
import React, { Component } from "react"
import './WhyBuy.scss'
 
class WhyBuy extends Component {

    render() {    
        return (
            <div className="whyBuyContainer">
                <div className="header">Why join the PokePunkz Club?</div>
                <div className="paragraph">
                    Think of NFTs as a trading card Game for anyone who loves collecting. PokePunkz are not only a collectable,
                    they can be used as a unique token or physical coin that you always have in your pockets. These coins will be ready for battle. When you are chilling in the
                    metaverse, face off against your friends or other PokePunkz. 
                    Each PokeCoin will receive stats based off their PokeHat they are wearing. Stats will be viewable on the website 
                    by clicking on any PokePunk. 
                    @:<a href="https://pokepunkz.io/pokePunkz" rel="noreferrer">pokepunkz.io/pokePunkz</a>.
                    <br/><br/>

                    Maybe you want a PokePunk because you're craving that nostalgia that normal life cant supply anymore.
                    Oh and I should mention the 151 unique Holographics in the collection, join now to be part giveaways and future content.
                </div>
            </div> 
        )
    }
}

export default WhyBuy;