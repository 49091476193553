let timelineElements = [{
		id: 1,
		title: "Start of Launch",
		location: "Discord",
		description: "Join the discord and choose your starter PokePunk !",
		buttonText: "join DISCORD",
		url: "https://discord.gg/kEy6uB6C3H",
		date: "",
		icon: "ball"
	},
	{
		id: 2,
		title: "Initial Drop",
		location: "Opensea",
		buttonText: "Buy on Opensea",
		url: "https://opensea.io/collection/pokepunkz",
		description: "PokePunkz CARD PACKS will be available for purchase at 0.05ETH each. Packs will be dropped in increments of 25-100.",
		date: "",
		icon: "ball"
	},
	{
		id: 3,
		title: "HOLOGRAPHICS",
		location: "Opensea and Discord",
		description: "HOLOGRAPHICS have a ~7.5% chance to be in any PokePunkz Pack. The first 200 packs will be first edition.",
		buttonText: "View Currently minted PokePunkz",
		url: "/pokePunkz",
		date: "",
		icon: "ball"
	},
	{
		id: 4,
		title: "Continued Drops & Giveaways",
		location: "Twitter",
		description: "Rare card packs will be given away, looking to build a strong community.", 
		buttonText: "Be sure to follow on twitter",
		url: "https://twitter.com/PokePunkzNft",
		date: "",
		icon: "ball"
	},
	{
		id: 5,
		title: "Continued Nostalgia",
		location: "Skills & Abilities",
		description: "PokePunkz are appointed random, but uniquely fair, stats. They will also be able to adopt unique sets of skills. (viewable on the website)",
		buttonText: "pokepunkz.io/pokepunkz",
		url: "http://pokepunkz.io/pokepunkz",
		date: "",
		icon: "ball"
	},
	{
		id: 6,
		title: "POKEPUNK Battles",
		location: "Arena",
		description: "A new era of NFTs are here and they are ready to battle. Use your PokeCoins against other Punkz to win rewards.",
		date: "",
		icon: "ball"
	}, 
];

export default timelineElements;