import React from "react"
import './PokePunkExamples.scss' 
import s1 from '../../files/images/examples/s1.gif'
import s2 from '../../files/images/examples/s2.gif'
import s3 from '../../files/images/examples/s3.gif'
import s4 from '../../files/images/examples/s4.gif'
import s5 from '../../files/images/examples/s5.gif' 
import s6 from '../../files/images/examples/s6.gif'
import s7 from '../../files/images/examples/s7.gif'
import s8 from '../../files/images/examples/s8.gif'
import s9 from '../../files/images/examples/s9.gif'
import s10 from '../../files/images/examples/s10.gif'

const PokePunkExamples = () => 
    <div className="pokePunkExamplesWrap"> 
        <img src={s7} alt={"s7"}/> 
        <img src={s4} alt={"s4"}/>
        <img src={s6} alt={"s6"}/>
        <img src={s1} alt={"s1"}/> 
        <img src={s5} alt={"s5"}/>
        <img src={s2} alt={"s2"}/> 
        <img src={s8} alt={"s8"}/>
        <img src={s10} alt={"s10"}/>
        <img src={s9} alt={"s9"}/>
        <img src={s3} alt={"s3"}/>
    </div> 

export default PokePunkExamples;

